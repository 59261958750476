import axios, { AxiosError } from "axios";
import Cookies from 'universal-cookie';
import { cleanUpAllStorage, deleteAuthCookies } from "./get_set_user_information";

export const Get = async (path: string, needUserUuid?: boolean) => {
    const {token, userUuid} = getAuthData()
    CheckUserAuth(token, userUuid)
    const userId = needUserUuid ? `/${userUuid}` : '';
    const headers = getHeaders('default', token)
    try {
        const res = await axios.get(process.env.REACT_APP_API_URL + path + `${userId}`, {
            headers: headers,
            withCredentials: true,
        })

        return res.data
    } catch (e) {
        CheckForLogOut(e)
        console.log(e)       
        return e.response
    }
}

export const Post = async (path: string, body?: object, needUserUuid?: boolean) => {
    const {token, userUuid} = getAuthData()
    CheckUserAuth(token, userUuid)
    const userId = needUserUuid ? `/${userUuid}` : '';
    const headers = getHeaders('default', token)
    try {
        const res = await axios.post(process.env.REACT_APP_API_URL + path + `${userId}`, body, {
            headers: headers,
            withCredentials: true,
        })
        return res.data
    } catch(e) {
        CheckForLogOut(e)        
        return e.response
    }
}

export const uploadCSVFile = async (path: string, file: File, botUuid: string, needUserUuid?: boolean) => {
    const {token, userUuid} = getAuthData()
    CheckUserAuth(token, userUuid)
    const formData = new FormData();
    formData.append('file', file);
    formData.append('botUuid', botUuid);
    formData.append('userUuid', userUuid);
    const userId = needUserUuid ? `/${userUuid}` : '';
    const headers = getHeaders('upload', token)
    try {
        const res = await axios.post(process.env.REACT_APP_API_URL + path + `${userId}`, formData, {
            headers: headers,
            withCredentials: true,
        })
        return res;
    } catch(e) {
        CheckForLogOut(e)
        return e.response
    }
}

export const GetCronApi = async (path: string, needUserUuid?: boolean) => {
    const {token, userUuid} = getAuthData()
    CheckUserAuth(token, userUuid)
    const userId = needUserUuid ? `/${userUuid}` : '';
    const headers = getHeaders('cron', token)
    try {
        const res = await axios.get(process.env.REACT_APP_CRON_URL + path + `${userId}`, {
            headers: headers,
            withCredentials: true,
        })

        return res.data
    } catch (e) {
        CheckForLogOut(e)        
        return e.response
    }
}

export const PostCronApi = async (path: string, body?: object, needUserUuid?: boolean) => {
    const {token, userUuid} = getAuthData()
    CheckUserAuth(token, userUuid)
    const userId = needUserUuid ? `/${userUuid}` : '';
    const headers = getHeaders('cron', token)
    try {
        const res = await axios.post(process.env.REACT_APP_CRON_URL + path + `${userId}`, body, {
            headers: headers,
            withCredentials: true,
        })

        return res.data
    } catch (e) {
        CheckForLogOut(e)        
        return e.response
    }
}

const getAuthData = () => {
    const cookies = new Cookies();
    const userUuid = cookies.get('ht');
    const token = cookies.get('token-ht')

    return {token, userUuid}
}

const CheckUserAuth = (token: string, userUuid: string) => {
    if (!token || !userUuid) {
        logOut() 
    }
}

const getHeaders = (headersName: 'default' | 'upload' | 'cron' | 'export', token: string) => {
    switch(headersName) {
        case 'upload':
            return {
                'Cache-Control': 'no-cache',
                'Access-Control-Allow-Credentials': 'true',
                'Access-Control-Allow-Origin': 'https://app.heet.ai, https://dev.heet.ai',
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`
            }
        case 'export':
            return {
                'Cache-Control': 'no-cache',
                'Access-Control-Allow-Credentials': 'true',
                'Access-Control-Allow-Origin': 'https://app.heet.ai, https://dev.heet.ai',
                'Content-Type': 'text/csv',
                'Authorization': `Bearer ${token}`
            }
        case 'cron':
            return {
                'Cache-Control': 'no-cache',
                'Access-Control-Allow-Credentials': 'true',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        default: 
            return {
                'Cache-Control': 'no-cache',
                'Access-Control-Allow-Credentials': 'true',
                'Access-Control-Allow-Origin': 'https://app.heet.ai, https://dev.heet.ai',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
    }
}

const CheckForLogOut = (e: AxiosError) => {
    console.log(e)
    if (
        e.response.status !== 400 
        && e.response.status !== 404 
        && e.response.status !== 500
        ) {
        logOut()
    }
}

const logOut = () => {
      cleanUpAllStorage();
      deleteAuthCookies()
      window.location.href = '/signIn'
  };

  export const getRequest = async (path: string, needUserUuid?: boolean) => {
    const {token, userUuid} = getAuthData()
    CheckUserAuth(token, userUuid)
    const userId = needUserUuid ? `/${userUuid}` : '';
    const headers = getHeaders('default', token)
    try {
        const res = await axios.get(process.env.REACT_APP_API_URL + path + `${userId}`, {
            headers: headers,
            withCredentials: true,
        })
        return res
    } catch (e) {

        if(e.code === 'ERR_NETWORK') {
            return e
        }
        
        CheckForLogOut(e)       
        return e.response
    }
}

export const postRequest = async (path: string, body?: object, needUserUuid?: boolean) => {
    const {token, userUuid} = getAuthData()
    CheckUserAuth(token, userUuid)
    const userId = needUserUuid ? `/${userUuid}` : '';
    const headers = getHeaders('default', token)
    try {
        const res = await axios.post(process.env.REACT_APP_API_URL + path + `${userId}`, body, {
            headers: headers,
            withCredentials: true,
        })
        return res
    } catch (e) {

        if(e.code === 'ERR_NETWORK') {
            return e
        }
        
        CheckForLogOut(e)       
        return e.response
    }
}

export const postRequestExport = async (path: string, body?: object, needUserUuid?: boolean) => {
    const {token, userUuid} = getAuthData()
    CheckUserAuth(token, userUuid)
    const userId = needUserUuid ? `/${userUuid}` : '';
    const headers = getHeaders('default', token)
    try {
        const res = await axios.post(process.env.REACT_APP_API_URL + path + `${userId}`, body, {
            headers: headers,
            responseType: 'blob',
            withCredentials: true,
        })
        return res
    } catch (e) {

        if(e.code === 'ERR_NETWORK') {
            return e
        }
        
        CheckForLogOut(e)       
        return e.response
    }
}

export const deleteRequest = async (path: string, needUserUuid?: boolean) => {
    const {token, userUuid} = getAuthData()
    CheckUserAuth(token, userUuid)
    const userId = needUserUuid ? `/${userUuid}` : '';
    const headers = getHeaders('default', token)
    try {
        const res = await axios.delete(process.env.REACT_APP_API_URL + path + `${userId}`, {
            headers: headers,
            withCredentials: true,
        })
        return res
    } catch (e) {

        if(e.code === 'ERR_NETWORK') {
            return e
        }
        
        CheckForLogOut(e)       
        return e.response
    }
}

// async function Get(path: string, needHt?: boolean) {
//     handleCookies();
    
//     const url = new URL(window.location.href);
//     const searchParams = new URLSearchParams(url.search);
//     const tokenPath = searchParams.get('token') || '';
//     if (tokenPath) {
//         await checkIfEmailIsConfirmed(tokenPath)
//     }
//     let login = true
//     const cookies = new Cookies();
//     const ht = needHt ? `/${cookies.get('ht')}` : '';
//     if (!cookies.get('ht')) {
//         window.location.href = '/signIn'
//     }

//     axios.defaults.withCredentials = true
//     const token = localStorage.getItem('token')
//     const headers = {
//         'Cache-Control': 'no-cache',
//         'Access-Control-Allow-Credentials': 'true',
//         'Access-Control-Allow-Origin': 'https://app.heet.ai, https://dev.heet.ai',
//         'Content-Type': 'application/json',
//         'Authorization': `Bearer ${token}`,
//     }

//     const res = await axios.get(process.env.REACT_APP_API_URL + path + `${ht}`, {
//         headers: headers,
//         withCredentials: true,
//     }).then(res => {        
//         return res.data;
//     }).catch(e => {
//         if (e.response.status !== 400 && e.response.status !== 404 && e.response.status !== 500) { //&& e.response.status !== 403 && e.response.status !== 500) {
//             login = false
//         }
        
//         return e.response
//     })

//     if (!login) {
//         window.location.href = '/signIn'
//     } else {
//         return res
//     }
// }

// export async function Post(path: string, body?: object, needHt?: boolean) {
//     handleCookies();
//     const url = new URL(window.location.href);
//     const searchParams = new URLSearchParams(url.search);
//     const tokenPath = searchParams.get('token') || '';
//     if (tokenPath) {
//         await checkIfEmailIsConfirmed(tokenPath)
//     }
//     let login = true
//     const cookies = new Cookies();
//     const ht = needHt ? `/${cookies.get('ht')}` : '';
//     if (!cookies.get('ht')) {
//         window.location.href = '/signIn'
//     }
//     const token = localStorage.getItem('token')
//     const headers = {
//         'Cache-Control': 'no-cache',
//         'Access-Control-Allow-Credentials': 'true',
//         'Access-Control-Allow-Origin': 'https://app.heet.ai, https://dev.heet.ai',
//         'Content-Type': 'application/json',
//         'Authorization': `Bearer ${token}`
//     }

//     const res = await axios.post(process.env.REACT_APP_API_URL + path + `${ht}`, body, {
//         headers: headers,
//         withCredentials: true,
//     }).then(res => {
//         return res.data;
//     }).catch(e => {
//         if (e.response.status !== 400 && e.response.status !== 404 && e.response.status !== 500) { //&& e.response.status !== 403 && e.response.status !== 500) {
//             login = false
//         }

//         return e.response
//     })

//     if (!login) {
//         window.location.href = '/signIn'
//     } else {
//         return res
//     }
// }

// async function checkIfEmailIsConfirmed(tokenPath: string) {
//     if (tokenPath) {
//         await axios.post(process.env.REACT_APP_API_URL + '/auth/confirm', {
//             token: tokenPath
//         }).then(res => {
//             cleanUpAllStorage();
//             setUserInformationTolocalStorage(res.data);
//             localStorage.removeItem('token');
//             window.location.href = '/signIn'
//             handleCookies()
//             return res.data;
//         }).catch(e => {
//             console.log(e)
//         })
//     }
// }

// function handleCookies(newUuid = '') {
//     const cookies = new Cookies();
//     if (newUuid !== '') {
//         cookies.set('ht', newUuid, { path: '/', sameSite: 'none', secure: true });
//         cookies.set('stageHT', newUuid, { path: '/', sameSite: 'none', secure: true });
//         cookies.set('isMember', true, { path: '/', sameSite: 'none', secure: true });
//     }
//     if (cookies.get('ht') !== cookies.get('stageHT')) {
//         cookies.set('ht', cookies.get('stageHT'), { path: '/', sameSite: 'none', secure: true });
//     }
// }
// export default Get;
