import React from 'react';
import { useTableLeadsData } from '../../../../utils/redux/storeHooks/dataHooks/useTableLeadsData';
import { LoadingList } from './LoadingList';
import { TeamList } from './TeamList';
import UserInformation from '../../../../types/entities/user_information';
import { NoUserList } from './NoUsersList';

type Props = {
  onSelectUserHandler: (user: UserInformation) => void;
  selectUserForParse: UserInformation | null;
}

export const TeamListFlow = ({ onSelectUserHandler, selectUserForParse }: Props) => {
  const { isTeamListLoading, teamList } = useTableLeadsData();

  if (isTeamListLoading) {
    return <LoadingList />
  }

  if (teamList.length === 0) {
    return <NoUserList />
  }

  return <TeamList
    onSelectUserHandler={onSelectUserHandler}
    selectUserForParse={selectUserForParse}
  />
}

